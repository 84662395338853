exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-back-care-one-to-one-js": () => import("./../../../src/pages/back-care-one-to-one.js" /* webpackChunkName: "component---src-pages-back-care-one-to-one-js" */),
  "component---src-pages-contact-me-js": () => import("./../../../src/pages/contact-me.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-courses-and-classes-js": () => import("./../../../src/pages/courses-and-classes.js" /* webpackChunkName: "component---src-pages-courses-and-classes-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-foundation-training-js": () => import("./../../../src/pages/foundation-training.js" /* webpackChunkName: "component---src-pages-foundation-training-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-massage-js": () => import("./../../../src/pages/massage.js" /* webpackChunkName: "component---src-pages-massage-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

